@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components{
  .ams-button{
    @apply p-2 text-white bg-ams-blue rounded
  }
  .ams-card-manu{
    @apply rounded p-2 shadow cursor-pointer duration-200 hover:-translate-y-1 hover:bg-gray-100;
    height: 100px;
    align-content: center;
  }
  .ams-card{
    @apply rounded p-2 shadow cursor-pointer duration-200 hover:-translate-y-1 hover:bg-gray-100;
    align-content: center;
  }
}
